.whiteBackground {
    background-color: white;
    height: 100vh;
}
.bottom {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    left: 0;
}