h1.title {
	color: #a90000;
	font-family: arial;
	font-size: 42px;
	text-align: center;
	padding:0px;
}

h2 {
	color: #a90000;
	font-family: arial;
	font-size: 32px;
	text-align: left;
	padding:0px;
}

h3.bigger {
	font-family:arial;
	margin-top:0px;
	font-weight: bolder;
	font-size: 32px;
}

p {
	text-indent: 30px;
	color: #00554a;
	font-family: arial;
	font-weight: medium;
	font-size: 16px;
	text-align: left;
	line-height: 30px;
}

p.small {
	font-size:14px;
}

body.wumbobody {
	margin: 0px;
	padding: 0px;
	background-color: #000000;
}

ul.normalList {
	color: #00554a
}

ul.directory {
	list-style-type: none;
	text-align: center;
	width: 100%;
	padding: 0px;
	margin: 0px;
}

ul.directory li {
	display:inline-block;
    *display:inline; /*IE7*/
    *zoom:1; /*IE7*/
    background:#000000;
	padding-right: 30px;
	padding-left: 30px;
	padding-top: 2px;
	padding-bottom: 2px;
	margin-bottom: 10px;
    color: #00554a;/*#9a0000;*/
	font-family: arial;
	font-weight: bold;
	font-size: 20px;
    margin-right:2%;
}

ul.horizontallist {
	list-style-type: none;
	text-align: center;
	width: 100%;
	padding: 0px;
	margin: 0px;
}

ul.horizontallist li {
	display:inline-block;
    *display:inline; /*IE7*/
    *zoom:1; /*IE7*/
    background:#000000;
	padding-right: 5px;
	padding-left: 5px;
	padding-top: 2px;
	padding-bottom: 2px;
	margin-bottom: 10px;
    color: #00554a;/*#9a0000;*/
	font-family: arial;
	font-weight: bold;
	font-size: 20px;
    margin-right:2%;
}

div.header {
	background-color: #000000;
	width: 100%;
	padding: 0%;
	margin-top: 0px;
}

div.maintext {
	background-color: #e0e0ff;
	width: 1200px;
	margin-left: auto;
	margin-right: auto;
	margin-top:0%;
	margin-bottom: 20px;
	padding: 15px;
}

br.clearAll {
	clear: both;
}

/*used for zero*/
div.black {
	background-color: #000000;
}

div.indented {
	margin-top: 20px;
	margin-right: 40px;
	margin-left: 40px;
	margin-bottom: 20px;
}

div.gamepanel {
	margin-top: 20px;
	margin-right: 10px;
	margin-left: 10px;
	margin-bottom: 20px;
}

div.photo {
	float: left;
	padding-left: 30px;
	padding-right: 30px;
}

div.gamedescription {
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 30px;
	padding-right: 30px;
}

div.photoright {
	float:right;
}

div.extrapadding {
	padding-left: 50px;
	padding-right: 50px;
}

div.photolist {
	float: left;
	padding-left:30px;
	padding-right:30px;
	padding-top:15px;
	padding-bottom:15px;
}

div.photolistright {
	float: right;
	padding-left:30px;
	padding-right:30px;
	padding-top:15px;
	padding-bottom:15px;
}

div.form-group {
	color: #00554a;
}

div.loadingdiv {
	color: #00554a;
}

div.zeroText {
	color: #00554a;
	font: "Arial";
	font-family: arial;
	font-weight: medium;
	font-size: 24px;
}

div.largeBlock {
	color: #ff0000;
	width: 100%;
	height: 5000px;
}
/* end used for zero */

/* Primary buttons */
button.wumboprimary{
	background-color: #00554a;
	color:#e0e0ff;
}
button.wumboprimary:active {
	background-color: #000000;
	color:#e0e0ff;
}
button.wumboprimary:hover {
	background-color: #002222;
	color:#e0e0ff;
}

/* For now, just use the bootstrap built in secondary buttons*/

/* checkmarks and X boxes in LGM Sniper */
td.checkmark {
	background-color: #99ff99;
}
td.xmark {
	background-color: #ff9999;
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

a.directory:link {
	color: #00554a;
	text-decoration: none;
}

a.directory:visited {
	color: #00554a;
	text-decoration: none;
}

a.directory:active {
	color: #a90000;
	text-decoration: underline;
}

a.titleLink {
	padding-left: 15px;
	padding-right: 15px;
}

.centeredText {
	text-align: center;
}

.rightCentered {
	position: absolute;
	right: 0px;
	margin-right: 15px;
	margin-top: 5px;
}

input.fullWidth {
	width: 100%;
}

input.custom-range {
	color: #000000;
}

.limitedMaxWidth {
	max-width: 1000px;
}
/* end LGM sniper buttons */


/* Point Line Duality */
div.darkGreyBackground {
	display: inline-block;
	background-color: darkslategray;
	padding: 10px;
}
div.lightGreyBackground {
	display: inline-block;
	background-color: lightslategray;
	padding: 10px;
}
div.roundedRedBox {
	border-radius: 10px;
	width: 30px;
	height: 30px;
	background-color: red;
}
div.roundedGreenBox {
	border-radius: 10px;
	width: 30px;
	height: 30px;
	background-color: green;
}
div.roundedBlueBox {
	border-radius: 10px;
	width: 30px;
	height: 30px;
	background-color: blue;
}
div.roundedPinkBox {
	border-radius: 10px;
	width: 30px;
	height: 30px;
	background-color: pink;
}
div.roundedPurpleBox {
	border-radius: 10px;
	width: 30px;
	height: 30px;
	background-color: purple;
}
.canvasSize {
	width: 500px; 
	height: 500px;
	margin: 30px;
}

.alignChildrenHorizontally {
	display: inline;
}
/* End point line duality */

.mediumPadding {
	margin-top: 30px;
	margin-bottom: 30px;
}

